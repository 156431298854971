import "./style.css";

import { json, Link } from "react-router-dom";

const Blog = ({ blog, index, updateBlogPublished }) => {
  const publishBlog = (published) => {
    fetch(
      `/api/v2/publish-blog`, { method: "POST", body: JSON.stringify({ blog_id: blog.post_id, published: published }) }
    )
      .then((response) => response.json())
      .then((res) => {
        updateBlogPublished(index, published)
        // setBlogs(res.data);
      });
  }
  return (
    <div>
      <div className="row flex-nowrap  ">
        <div className="col overflow-hidden">
          <p className="blog-title text-truncate">{blog.blog_title}</p>
        </div>
        <div className="col-auto">
          <div className="blog-status">
            <div className="row gx-0" style={{ alignItems: "center" }}>
              <div className="col-auto">
                {!blog.published ? "Not live" : "Live"}
              </div>
              <div className="col-auto">
                <span className={blog.published ? "published" : ""} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row flex-nowrap">
        <div className="col-auto">
          <img className="blog-image" src={blog.blog_image} />
        </div>
        <div className="col overflow-hidden">
          <p className="blog-description">{blog.blog_description}</p>
          <div className="row ">
            {!blog.published && (
              <div className="col-auto">
                <button className="publish-status-button" onClick={()=>publishBlog(true)}>Publish</button>
              </div>
            )}
            <div className="col-auto">
              <Link to={`/edit-blog/${blog.post_id}`}>
                <button className="edit-blog-button">Edit</button>
              </Link>
            </div>
            {blog.published && (
              <div className="col-auto">
                <button className="unpublish-status-button" onClick={()=>publishBlog(false)}>Un publish</button>
              </div>
            )}
            {blog.published && (
              <div className="col-auto">
                <button className="delete-blog-button">Delete</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Blog;
