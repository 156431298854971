import "../createBlog/style.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchBlog, setBlog } from "../../store/blogSlice";
import Editor from "../editor";

const EditBlogContent = () => {
  const [blogContent, setBlogContent] = useState("");
  const [blogContentCopy, setBlogContentCopy] = useState("");
  const [change, setChange] = useState(false);

  const blogState = useSelector((state) => state.blog);
  const dispatch = useDispatch();

  const { blog_id } = useParams();
  const inputPost = (e) => {
    setBlogContentCopy(e.target.value);
    if (!change) {
      setChange(true);
    }
  };
  useEffect(() => {
    if (blogState.blog_id !== blog_id) {
      dispatch(fetchBlog(blog_id));
    }
  }, [blogState.blog_id, blog_id, dispatch]);
  useEffect(() => {
    setBlogContentCopy(blogState.blog_content);
    setBlogContent(blogState.blog_content);
  }, [blogState.blog_content, blogState.blog_id]);

  const submit = async () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(blogContentCopy, "text/html");

    const imgElements = doc.querySelectorAll("img");
    const requests = Array.from(imgElements).map(async function (img) {
      if (img.src.startsWith("data:image")) {
        const response = await fetch("/api/v2/image-upload", {
          method: "post",
          body: JSON.stringify({
            blog_id: blog_id,
            blog_image: img.src,
          }),
        });
        img.src = response.data.image;
        return Promise.resolve();
      }
      return Promise.reject();
    });
    Promise.all(requests).then(function () {
      const modifiedHtmlString = doc.body.innerHTML;
      const textLength = doc.body.innerText.split(" ").length;
      const readingTime = (textLength / 200).toFixed() || 1;
      setBlogContent(modifiedHtmlString);
      fetch("/api/v2/update-post-content", {
        method: "POST",
        body: JSON.stringify({
          blog_id: blog_id,
          reading_time: readingTime,
          blog_content: modifiedHtmlString,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 1) {
            // navigate("/blogs")
          }
        });
    });
  };
  const publishBlog = (published) => {
    fetch("/api/v2/publish-blog", {
      method: "POST",
      body: JSON.stringify({ blog_id: blog_id, published: published }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 1) {
          dispatch(setBlog({ ...blogState, published: published }));
        }
      });
  };
  return (
    <>
      <div className="row">
        <div className="col" style={{ overflow: "hidden" }}>
          {blogContent && (
            <Editor initialValue={blogContent} onChange={(e) => inputPost(e)} />
          )}
        </div>
        <div className="col-auto">
          <div className="sidebar-post card-block">
            <div className="row">
              <div className="col-auto">
                <button
                  className="blog-edit-button save-blog-button"
                  disabled={change}
                  onClick={submit}
                >
                  Save and exit
                </button>
              </div>
              <div className="col-auto">
                <button
                  className="blog-edit-button create-blog-button"
                  disabled={!change}
                  onClick={() => {
                    window.open(
                      "https://nuctro.com/preview/" + blog_id,
                      "_blank",
                    );
                  }}
                >
                  Preview
                </button>
              </div>
              <div className="col-auto">
                {!blogState.published ? (
                  <button
                    className="blog-edit-button publish-blog-button"
                    disabled={!change}
                    onClick={() => publishBlog(true)}
                  >
                    Publish
                  </button>
                ) : (
                  <button
                    className="blog-edit-button unpublish-blog-button"
                    disabled={!change}
                    onClick={() => publishBlog(false)}
                  >
                    Un publish
                  </button>
                )}
              </div>
            </div>
            <p>
              <b>Title</b>
            </p>
            <p className="title">{blogState.blog_title}</p>
            <p>
              <b>Description</b>
            </p>
            <p>{blogState.blog_description}</p>
            <p>
              <b>Image</b>
            </p>
            <img src={blogState.blog_image} />
            <p>
              <b>Meta tag</b>
            </p>
            <p>{blogState.meta_tag}</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditBlogContent;
