import GoogleLoginButton from "../../component/googleLoginButton";
import Image1 from "../icons/dashboard-1.svg";
import Image2 from "../icons/dashboard-2.svg";
import Image3 from "../icons/dashboard-3.svg";
import Image4 from "../icons/dashboard-4.svg";

const Home = () => {
  return (
    <div className="container-box">
      <div className="row">
        <div className="col">
          <div className="card dashbaoard-card card-content-1">
            <p>Views</p>
            <span>10</span>
            <img src={Image1} />
          </div>
        </div>
        <div className="col">
          <div className="card dashbaoard-card card-content-2">
            <p>Followers</p>
            <span>10</span>

            <img src={Image2} />
          </div>
        </div>
        <div className="col">
          <div className="card dashbaoard-card card-content-3">
            <p>Likes</p>
            <span>10</span>

            <img src={Image3} />
          </div>
        </div>
        <div className="col">
          <div className="card dashbaoard-card card-content-4">
            <p>Comments</p>
            <span>10</span>

            <img src={Image4} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
