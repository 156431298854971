import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function useRefValue(ref) {
  return ref.current;
}
export default function GoogleLoginButton() {
  const [loginInitite, setloginInitiate] = useState(false);
  const navigate = useNavigate();

  const ref = useRef();
  const refValue = useRefValue(ref);

  useLayoutEffect(() => {
    const url = "https://accounts.google.com/gsi/client";
    const script = document.createElement("script");
    script.async = true;
    script.src = url;
    document.head.appendChild(script);
    setTimeout(() => {
      const button = document.createElement("div");
      button.classList.add("g_id_signin");
      button.setAttribute("data-type", "standard");
      button.setAttribute("data-size", "standard");
      button.setAttribute("data-theme", "outline");
      button.setAttribute("data-text", "sign_in_with");
      button.setAttribute("data-shape", "rectangular");
      button.setAttribute("data-logo_alignment", "left");
      ref?.current?.appendChild(button);
      setloginInitiate(true);
    }, 300);
  }, [refValue]);
  useEffect(() => {
    const { google } = window;
    const googleOpt = {
      isOneTap: false,
    };
    if (ref.current && google) {
      google.accounts.id.initialize({
        client_id:
          "1078797443541-5rbrj05pfp57l9usr1jj9gta11aket7k.apps.googleusercontent.com",
        callback: async (response) => {
          fetch("/api/auth/google-auth", {
            method: "POST",
            body: JSON.stringify(response),
          })
            .then((response) => response.json())
            .then(() => {
              navigate("/");
            });
        },
        ...googleOpt,
      });

      if (googleOpt.isOneTap) {
        google.accounts.id.prompt();
      }
    }
  }, [loginInitite, navigate]);
  return <div ref={ref}></div>;
}
export function GoogleLogOutButton({ children }) {
  const navigate = useNavigate();

  return (
    <>
      <div
        onClick={() => {
          fetch("/api/auth/google-auth-logout", {
            method: "GET",
          })
            .then((response) => response.json())
            .then(() => {
              navigate("/login");
            });
        }}
      >
        {children}
      </div>
    </>
  );
}
