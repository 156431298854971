import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  let response = await fetch("/api/v2/user-profile");
  response = await response.json();
  return response;
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    user_id: "",
    loginStatus: "",
  },
  extraReducers(builder) {
    builder
      // eslint-disable-next-line no-unused-vars
      .addCase(fetchUser.pending, (state, action) => {})
      .addCase(fetchUser.fulfilled, (state, action) => {
        if (action.payload.status === 1) {
          state.loginStatus = "authorized";
          state.image = action.payload.data.image;
          state.name = action.payload.data.name;
        } else {
          state.loginStatus = "login-required";
        }
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(fetchUser.rejected, (state, action) => {});
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = userSlice.actions;
export default userSlice.reducer;
