import "./index.css";

import { useCallback, useEffect, useState } from "react";

import Blog from "../../component/blogImage";

const Blogs = ({ published }) => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    fetch(
      `/api/v2/get-user-blogs${published === true || published === false ? "/?published=" + published : ""}`,
    )
      .then((response) => response.json())
      .then((res) => {
        setBlogs(res.data);
      });
  }, [published]);
  const updateBlogPublished = useCallback((index, published) => {
    let newBlogs= blogs
    console.log(index, newBlogs[index].published)
    newBlogs[index].published = published
    setBlogs([...newBlogs])
  }, [setBlogs])
  return ( 
    <>
      <div className="row">
        {blogs.map((blog, i) => {
          return (
            <div className="col-6 " key={i}>
              <div className="card-block blog-box">
                <Blog blog={blog} index={i} key={blog.blog_id} updateBlogPublished={updateBlogPublished} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default Blogs;
