import { useSelector } from "react-redux";

const Navbar = () => {
  const user = useSelector((state) => state.user);

  return (
    <nav className="navbar">
      <div className="container-fluid">
        <div className="row align-center" style={{ width: "100%" }}>
          <div className="col-auto">
            <img
              className="brand-image"
              src={"https://www.nuctro.com/assets/brand.png"}
            />
          </div>
          <div className="col-auto">
            <h2>Nuctro</h2>
          </div>
          <div className="col-auto align-content-center ms-auto">
            <span className="nav-user-name">{user.name}</span>
          </div>
          <div className="col-auto">
            <img className="nav-profile-image" src={user.image} />
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
