import "./style.css";

import { useEffect, useState } from "react";

const UserProfile = () => {
  const [userDetail, setUserDetail] = useState({});
  useEffect(() => {
    fetch("/api/v2/user-profile", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setUserDetail(response.data);
      });
  }, []);
  useEffect(() => {}, []);
  return (
    <>
      <div className="card-block user-profile">
        <div className="row">
          <div className="col-auto">
            <div className="profile-image">
              <img src={userDetail.image} />
              <div className="change-profile">
                Change Profile Picture
                <input type={"file"} />
              </div>
            </div>
            <div>
              <input type={"file"} />
            </div>
            <div>
              <button>Edit profile</button>
            </div>
          </div>
          <div className="col">
            <small>Name</small>
            <p className="user-info-box">{userDetail.name}</p>
            <small>User Id</small>
            <p className="user-info-box">@{userDetail.user_id}</p>
            <small>Headline</small>
            <p className="user-info-box">{userDetail.headline || "N/A"}</p>
            <small>Job/Current position</small>
            <p className="user-info-box">{userDetail.position || "N/A"}</p>
            <small>About you</small>
            <p className="user-info-box">{userDetail.biography || "N/A"}</p>
            <small>DOB (Not visible to other)</small>
            <p className="user-info-box">{userDetail.dob || "N/A"}</p>
            <small>Linkdin</small>
            <p className="user-info-box">{userDetail.linkdin || "N/A"}</p>
            <small>Website</small>
            <p className="user-info-box">{userDetail.website || "N/A"}</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserProfile;
