import "./style.css";

import { useCallback, useEffect, useState } from "react";

import ImageEditor from "../../component/imageEditor";
import Editor from "../editor";

const CreateBlog = () => {
  const [post, setPost] = useState({});
  const [imageToEdit, setImageToEdit] = useState(null);
  const [blogId, setBlogId] = useState(null);
  const [page, setPage] = useState(1);

  const inputPost = useCallback(
    (e) => {
      setPost({ ...post, [e.target.name]: e.target.value });
    },
    [post],
  );
  const handleImageChange = (event) => {
    setImageToEdit(event.target.files[0]);
    setPost({ ...post, blog_image: event.target.files[0] });
  };
  useEffect(() => { }, []);
  const submit = () => {
    if (!blogId) {
      fetch("/api/v2/create-post", {
        method: "POST",
        body: JSON.stringify(post),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 1) {
            setBlogId(response.data.post_id);
            setPage(2);
          }
        });
    } else {
      fetch("/api/v2/update-post", {
        method: "POST",
        body: JSON.stringify({ ...post, blog_id: blogId }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 1) {
            // setBlogId(response.data.post_id)
          }
        });
    }
  };

  const saveImage = (img) => {
    setPost({ ...post, blog_image: img });
    setImageToEdit(null);
  };
  return (
    <>
      {imageToEdit && (
        <ImageEditor imageToEdit={imageToEdit} updateImage={saveImage} />
      )}
      <div className="row">
        <div className="col" style={{ overflow: "hidden" }}>
          {page === 1 && (
            <div className="card card-block">
              <div className="card-header card-block-header">
                <div className="col-12">
                  <h5>Create Blog</h5>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Title of blog
                        </label>
                        <input
                          onChange={(e) => inputPost(e)}
                          value={post["title"]}
                          type="text"
                          name="title"
                          className="form-control"
                          id=""
                          aria-describedby=""
                          placeholder="Enter title"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Description of blog
                        </label>
                        <textarea
                          onChange={inputPost}
                          value={post["description"]}
                          type="text"
                          name="description"
                          className="form-control"
                          id=""
                          aria-describedby=""
                          placeholder="Enter description"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-sm-auto">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Image thumbnail
                          </label>
                          <div className="image-thumbnail">
                            {post.blog_image ? (
                              <img src={post.blog_image} alt="Selected" />
                            ) : (
                              "Click here or drop your image to upload"
                            )}
                            <input
                              type="file"
                              className="image-input"
                              onChange={handleImageChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm">
                        <div className="row">
                          <div className="col-12">
                            <div className="">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Meta tags of blog
                                </label>
                                <textarea
                                  onChange={inputPost}
                                  value={post["meta_keywords"]}
                                  type="meta_tag"
                                  name="meta_keywords"
                                  className="form-control"
                                  id=""
                                  aria-describedby=""
                                  placeholder="Enter meta tag"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <MultiSelectInput options={[
                              { name: 'React', value: 'react' },
                              { name: 'Next.js', value: 'nextjs' },
                              { name: 'Node.js', value: 'nodejs' },
                              { name: 'GraphQL', value: 'graphql' },
                              { name: 'Redux', value: 'redux' },
                              { name: 'JEST', value: 'jest' }
                            ]} />
                            <div className="">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Url of blog(optional e.g name-of-blog)
                                </label>
                                <input
                                  onChange={inputPost}
                                  value={post["blog_url"]}
                                  name="blog_url"
                                  className="form-control"
                                  id=""
                                  aria-describedby=""
                                  placeholder="blog URL"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <button
                              className="create-blog-button"
                              onClick={submit}
                            >
                              Create
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {blogId && page === 2 && <Editor onChange={(e) => inputPost(e)} />}
        </div>
        {blogId && (
          <div className="col-auto">
            <div className="sidebar-post card-block"></div>
          </div>
        )}
      </div>
    </>
  );
};
export default CreateBlog;

const MultiSelectInput = ({ options }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  // Add a tag to the selection
  const addTag = (option) => {
    if (!selectedOptions.some((selected) => selected.value === option.value)) {
      setSelectedOptions([...selectedOptions, option]);
      setSearchTerm('');
      setFilteredOptions(options);
    }
  };

  // Remove a tag from the selection
  const removeTag = (optionValue) => {
    setSelectedOptions(
      selectedOptions.filter((item) => item.value !== optionValue)
    );
  };

  // Handle input changes (filtering options)
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFilteredOptions(
      options.filter((option) =>
        option.name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  // Handle backspace key to remove the last tag
  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && searchTerm === '' && selectedOptions.length) {
      removeTag(selectedOptions[selectedOptions.length - 1].value);
    }
  };

  return (
    <div className="multi-select-input">
      <div className="input-wrapper">
        {selectedOptions.map((option, index) => (
          <span key={index} className="tag">
            {option.name}
            <button onClick={() => removeTag(option.value)}>&times;</button>
          </span>
        ))}
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Search and select..."
          className="inline-input"
        />
      </div>
      {filteredOptions.length > 0 && (
        <ul className="dropdown">
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              onClick={() => addTag(option)}
              className={
                selectedOptions.some((selected) => selected.value === option.value)
                  ? 'selected'
                  : ''
              }
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

// Usage example
const options = ['React', 'Next.js', 'Node.js', 'GraphQL', 'Redux', 'JEST'];



